// Custom Mixins
@import "mixins/main";
// Custom Imports
@import "declarations/main";
@import "elements/main";
@import "visuals/main";
@import "widgets/main";

html {
  width: 100%;

  body {
    position: relative;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif, sans-serif;
    min-width: 100%;
    height: 100%;
    background-color: #fcfaf6;
    overflow-y: scroll;
    overflow-x: hidden;
    @import "areas/main";
  }
} 
