// imports

// mainz

#foot {

  margin-top: 0;
  border-bottom: 0;
  padding-bottom: 10em;
  @include no_mobile{
    margin-left: auto;
    margin-right: auto;
    width: 1009px;
  }

}

.footer {
  color: #000000;
  text-align: center;
  padding-top: 4em;
  border-bottom: 0;

  max-width: 100%;
  height: 5em;
  bottom: 0;
  background-color: transparent;

  @include no_mobile {
    margin-left: 285px;

  }
}


.jamedamob {
  @include mobile{
    position: absolute;
    background-image: url('/pics/jameda.jpg');
    background-size: contain;
    width: 105px;
    height: 35px;
    margin-top: 100px;
    margin-bottom: 50px;
    left: 0;

  }

}

.gplusmob {
  @include mobile{
    position: absolute;
    background-image: url('/pics/google-plus.jpg');
    background-size: contain;
    width: 105px;
    height: 35px;
    bottom: 0;
    margin-bottom: -80px;
    left: 0;

  }
}


share-button {
  @include mobile {
    position: absolute;
    right: 0;
    margin-top: 100px;
    margin-bottom: 50px;
  }
  background: #3D464D;
  color: #ffffff;
  width: 105px;
  height: 35px;
  border-radius: 0;
  z-index: 50;
  @include no_mobile {
    position: fixed;
    bottom: 150px;

    left: 50%;
    margin-left: - 505px;

  }






}



