#myNav {
  @include mobile{
    position: fixed;
top: 150px;
      width: 100%;
      height: 100%;
  }
  .nav {


  }
@import "menu";
  @import "submenu";
  .navbar-right {
    right: 2em;
  }
}