#present {
  width: 100%;
  height: 400px;
  background-color: #111111;

}

.item {
  height: 100%;
  image-resolution: from-image;
  overflow: hidden;

}

.carousel-inner {
  border-bottom: solid 2px #ffffff;
}

.carousel-image {

  overflow: hidden;
  height: 100%;
  border-left: transparent 5px;
  border-right: transparent 5px;
  position: relative; /* allows repositioning */
  left: 100%; /* move the whole width of the image to the right */
  margin-left: -200%;
}

.image-container {
  display: block;
  position: relative;
  height: 400px;
  width: 100%;

}

.image {
  position: absolute;
  border-left: transparent 5px;
  border-right: transparent 5px;
  height: 400px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.image2 {
  width: 100%;
  margin-bottom: 2em;
  border: 2px solid #601955;
}

.background-image2 {
  z-index: -1;
  position: absolute;

  left: 300px;
  height: 400px;
  width: 749px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);

  @include mobile {
    left: 0;
    width: 100%;
  }
}


.carousel-background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center center;
  background-repeat: repeat-x;
  background-size: auto 100%;
  -webkit-transform: scaleX(-1);
  -khtml-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  z-index: 0;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}

.background-image {
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
