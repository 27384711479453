h1 {
  font-size: 1.3em;
  color: #5b575f;
}

h2 {
  color: #5b575f;
}

h3 {
  font-size: 1.3em;
  color: #5b575f;
}

a {
  color: #601955;
}

a:link {
  color: #601955;
}

a:hover {
  color: #000000;
  text-decoration: none;
}

.link {
  cursor: pointer;
}

hr {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid #601955;
  margin: 0 0 2px 0;
  padding: 0;
}
.list {
  ul {
    background: #c3c3c3;
    padding: 20px;
    color: #000000;
    font-weight: bold;
    list-style: square;
    list-style-position: inside;
    li {
      color: #1a1a1a;
      margin: 5px;
    }
  }
}


strong {
  color: #444444;
}