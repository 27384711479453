// imports

// main
@include mobile {
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .navbar-header .collapse, .navbar-toggle {
    display: block !important;
  }
  .navbar-header {
    float: none;
  }
}

#head {
  z-index: 50;
  background-color: transparent;
  height: 170px;
  @include no_mobile {
    width: 1009px;
  }

  margin: auto;

  .navbar-default {
    background-color: transparent;

    z-index: 50;
    top: 0;
    position: relative;
    width: 100%;
    height: 150px;
    vertical-align: middle;
    border: 0;
    color: black;
    background-size: 1px 8px;
    background-position: 0 92px;
    background-repeat: repeat-x;

    .container-fluid {
      margin: 0;
      padding: 0;

      .navbar-header {
        width: 260px;
        height: 100%;

        @include no_mobile{
          position: fixed;
        }

        @include mobile {
          height: 150px;
          width: 100%;
          padding: 0;
          margin: 0;
        }
        .navbar-toggle {
          position: fixed;
          background-image: url('/pics/menü.png');
          top: 50px;
          right: 1em;
          width: 50px;
          height: 35px;
          color: #601955;
          border: 0;
          padding: 0;

        }
        .navbar-toggle:hover,
        .navbar-toggle:focus {
          background-color: transparent;
        }

        .navbar-left {
          top:0;
          left:0;
          float: none !important;
          width: 260px;
          height: 130%;
          @include no_mobile{
            position: static;
            background: #fcfaf6;

            box-shadow: 0 0 10px #000000;
          }

        }

        .navbar-brand {
          margin-top:20px;
          margin-left:15px;
          position: absolute;
          background-image: url('/pics/logo3.png');
          @include no_mobile{

            position: static;
          }
          width: 230px;
          height: 110px;


          font-size: 2em;
          text-align: justify;
          background-size: contain;
          padding: 0;
          cursor: pointer;
          background-repeat: no-repeat;
        }
        .navbar-brand-small {
          position: absolute;
          width: 220px;
          height: 43px;
          left: 1.5em;
          background-size: contain;
          margin-left: 0;
          padding: 0;
          cursor: pointer;
          background-repeat: no-repeat;
        }
        .link {

        }
        .sublink {

        }
        .jameda {
          @include no_mobile {
            z-index: 100;
            background-image: url('/pics/jameda.jpg');
            position: fixed;
            bottom: 100px;
            margin-left: -245px;
            width: 105px;
            height: 35px;
            background-size: contain;
          }
        }

        .googleplus {
          @include no_mobile {
            z-index: 100;
            background-image: url('/pics/google-plus.jpg');
            position: fixed;
            bottom: 50px;
            margin-left: -245px;
            width: 105px;
            height: 35px;
            background-size: contain;
          }
        }

      }
      .navbar-collapse {

        @include mobile {
          margin-left: 0;
          margin-right: 0;

          margin-top: 0;
          top: 0;
          border-top: none;
          box-shadow: none;
          padding: 0;
        }
      }
      .collapse {

      }
      .in {

      }
      @import "navigation";
    }
  }
  .navbar {
  }
}

