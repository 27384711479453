.top-button {
  position: fixed;
  background-image: url('/pics/top.png');
  width: 50px;
  height: 35px;
  left: 50%;
  margin-left: -312px;
  bottom: 30px;
  cursor: pointer;
  display: none;
  @include mobile {
    left: auto;
    right: 2em;
  }
}

.btn-primary {
  background-color: #601955;
  border: 1px solid #000000;
}

.btn-primary:hover {
  color: #000000;
  background-color: #01997E;
  border: 1px solid #000000;
}
