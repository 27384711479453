.navbar-nav {
  border: 0;
  margin: 0;
  margin-left: 270px;

  @include mobile {
    position: fixed;
    left: 0;
    padding: 0;
    margin: 0;
    top: 150px;
  }
  li {

    @include mobile {
      background-image: url('/pics/sublinks.png');
      background-repeat: repeat-y;
      width: 200px;
      margin-left: 0;
      margin-bottom: 20px;
      float: none;
    }
    a {
      min-width: 100px;
      padding-top: 3em;


      height: 170px;
      margin-left: 16px;
      background-image: url('/pics/links.png');
      color: #ffffff;
      font-size: 1.2em;
      font-weight: bold;
      cursor: pointer;


      @include no_mobile{
        text-align: center;
        background-size: 100% 125px;
        background-repeat: no-repeat;

        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
      }

      @include mobile {
        height: 50px;
        padding-left: 1em;
        padding-bottom: 0;
        padding-top: 1em;


        margin-left: 0;
        background-repeat: repeat-y;
        color: #ffffff;
        font-size: 1em;
        font-weight: bold;
        cursor: pointer;
      }
    }
    a:hover {
      color: #ffffff;
      font-size: 1.2em;
      font-weight: bold;
      height: 170px;
      background-image: url('/pics/link_active.png');
      @include no_mobile{
        background-size: 100% 170px;
        background-repeat: no-repeat;
      }
      @include mobile {
        color: #ffffff;
        font-size: 1em;
        font-weight: bold;
        height: 50px;
        width: 150px;
        background-image: url('/pics/sublink_active.png');
        background-size: contain;
        background-repeat: repeat-y;
      }
    }
    a:focus {
      color: #ffffff;
      font-size: 1.2em;
      font-weight: bold;
      height: 170px;
      background-color: transparent;
      @include no_mobile{

      }
      @include mobile {
        color: #ffffff;
        font-size: 1em;
        font-weight: bold;
        height: 50px;
        width: 150px;
        background-color: transparent;
        background-size: contain;
        background-repeat: repeat-y;
      }
    }
  }
  .link {

  }
  .sublink {

  }
  #kontakt {
    @include no_mobile{width: 121px;}
  }
  #qualifikationen {
    @include no_mobile{width: 190px;}
  }
  #leistungen {
    @include no_mobile{width: 190px;}
  }
  #dvt {
    @include no_mobile{width: 121px;}
  }
  .active {
    height: 170px;
    background-image: url('/pics/link_active.png');
    @include no_mobile{
      background-size: 100% 170px;
      background-repeat: no-repeat;
    }
    @include mobile {
      height: 50px;
      width: 150px;
      background-image: url('/pics/sublink_active.png');
      background-size: contain;
      background-repeat: repeat-y;
    }
  }
}