.navbar-sub {
  position: fixed;
  @include no_mobile{
    left: 50%;
    margin-left: -374px;
  }

  top: 200px;
  height: 44px;
  float: left;
  font-size: 0.8em;

  @include mobile {
    position: fixed;
    top: 175px;
    height: auto;
    font-size: 0.8em;
    right: 0;
    float: right;
  }
  li {
    width: 148px;
    margin-top: 1em;
    margin-bottom: 1em;
    background-image: url('/pics/sublinks.png');
    background-repeat: repeat-y;

    @include mobile {
      width: 150px;
      margin-bottom: 20px;
      background-image: url('/pics/sublinks.png');
      background-position: right;
      background-repeat: repeat-y;
    }
    a {
      height: 50px;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #ffffff;
      font-size: 1.2em;
      font-weight: bold;
      cursor: pointer;

      @include mobile {
        color: #ffffff;
        font-size: 1.2em;
        font-weight: bold;
        cursor: pointer;
        text-align: right;
      }
    }
    a:hover {
      background-color: transparent;
      background-image: url('/pics/sublink_active.png');
      color: #ffffff;
      font-size: 1.2em;
      font-weight: bold;

      @include mobile {
        color: #ffffff;
        font-size: 1.2em;
        font-weight: bold;
        background-image: url('/pics/sublink_active_reverse.png');
        background-color: transparent;
      }
    }
    a:focus {
      background-color: transparent;
      color: #ffffff;
      font-size: 1.2em;
      font-weight: bold;

      @include mobile {
        color: #ffffff;
        font-size: 1.2em;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
  .link {

  }
  .sublink {

  }
  .active {
    background-image: url('/pics/sublink_active.png');
    color: #ffffff;

    @include mobile {
      background-image: url('/pics/sublink_active_reverse.png');
      color: #ffffff;
    }
  }
}