textarea {
  min-height: 300px;
  resize:vertical;
}

input {
  margin-top: 1em;
}

.form-control:focus {
  border-color: #601955;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(100,100,100,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(100,100,100,.6);
}