// imports

//main

#content {
  z-index: 1;
  @include no_mobile {
    width: 1009px;
    margin: auto;
  }
}

.body {
  z-index: 1;
  width: 100%;
  padding-bottom: 2em;
}

.row {
  margin-bottom: 2em;
}

#loading {
  z-index: 1;
  @include no_mobile {
    width: 1009px;
    margin: auto;
  }
}

.container {
  text-align: start;
  max-width: 700px;
  background-color: transparent;
  font-size: 1.2em;
  line-height: 1.6;

  @include no_mobile {
    margin-left: 285px;
    width: auto;
  }
}

